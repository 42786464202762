<template>
  <div v-if="question">
    <div class="flex">
      <sqr-input-text
        class="w-1/4 mr-4"
        :label="question.scaleFrom || 'From label'"
        :value="question.scaleFromLabel"
        @change="propSet('scaleFromLabel', $event)"
      />
      <sqr-input-select
        class="w-1/4 mr-4"
        label="From"
        :value="question.scaleFrom"
        :options="optionsFrom"
        @selected="propSet('scaleFrom', $event)"
      />
      <sqr-input-select
        class="w-1/4 mr-4"
        label="Until"
        :value="question.scaleUntil"
        :options="optionsTo"
        @selected="propSet('scaleUntil', $event)"
      />
      <sqr-input-text
        class="w-1/4"
        :label="question.scaleUntil || 'Until label'"
        :value="question.scaleUntilLabel"
        @change="propSet('scaleUntilLabel', $event)"
      />
    </div>
  </div>
</template>

<script>
import SqrInputSelect from '@/sqrd-ui/SqrInputSelect';
import SqrInputText from '@/sqrd-ui/SqrInputText';
export default {
  name: 'ScaleEdit',
  props: { question: Object },
  components: { SqrInputSelect, SqrInputText },
  computed: {
    optionsFrom() {
      return [0, 1].map(id => ({ id, label: id }));
    },
    optionsTo() {
      return [1, 2, 3, 4, 5, 6].map(id => ({ id, label: id }));
    }
  },
  methods: {
    propSet(prop, value) {
      this.$emit('change', { ...this.question, [prop]: value });
    }
  }
};
</script>
